<template>
  <div>
    <b-row>
      <b-col>
        <b-card
          title="Picking V2"
          class="text-center"
        >
          <h1 class="mb-2">
            Pedidos Pendientes Picking: <span class="text-info">{{ pedidosPicking.length }}</span>
          </h1>
          <hr>
          <Picking-list />
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="false">
      <b-col>
        <b-card>
          <pre>Total pedidos: {{ pedidosPicking.length }}</pre>
          <pre>{{ pedidosPicking }}</pre>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
// import Ripple from 'vue-ripple-directive'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import PickingList from './PickingList.vue'

import usePicking from './usePicking'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    PickingList,
  },
  data() {
    return {
      process: process.env,
    }
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const {
      fetchClientes,
      fetchPedidos,
      fetchProductos,
      fetchRutas,
      fetchVehiculos,
      itemsToMap,
      overviewPath,
      selectedToMarkers,
      selectedByMarker,
      pedidosSelected,
      updatePedidosSelected,
      // Nuevo Pedido
      isAddNewPedidoSidebarActive,
      // Edit Pedido
      pedidoSelect,
      isEditPedido,
      editPedido,
      // Picking Pedido
      isPickingPedido,
      picking,
      pickingVehiculo,
      rutas,
      isPickingVehiculo,
      isPickingPedidosToRutas,
      addPedido,
      rutasSelected,
      updateRutasSelected,
      updateMapView,
      updateMostrarOrden,
      itemsMostrarOrden,
      updateRutaFilter,
      rutaFilter,
      updateZonaFilter,
      zonaFilter,
      searchQuery,
      updateSearchQuery,
      isAddNewRutaSidebarActive,
      preCalculaRutas,
      calculaRutas,
      pickingToRutas,
      pickingToRutasOff,
      exportToExcel,
      exportRutasToExcel,
      fechaDesde,
      fechaHasta,
      test,
      rutaSelect,
      isEditRuta,
      editRuta,
      pedidosPicking,
    } = usePicking()

    const addRuta = () => {
      isAddNewRutaSidebarActive.value = true
    }

    // const preCalculaRutas = () => {
    //   console.log('preCalculaRutas')
    // }

    return {
      // Left Sidebar Responsive
      mqShallShowLeftSidebar,

      fetchClientes,
      fetchPedidos,
      fetchProductos,
      fetchRutas,
      fetchVehiculos,
      // Mapa
      itemsToMap,
      overviewPath,
      selectedToMarkers,
      selectedByMarker,
      pedidosSelected,
      updatePedidosSelected,
      // Nuevo Pedido
      isAddNewPedidoSidebarActive,
      // Nueva Ruta
      isAddNewRutaSidebarActive,
      // Edit Pedido
      pedidoSelect,
      isEditPedido,
      editPedido,
      // Picking Pedido
      isPickingPedido,
      picking,
      pickingVehiculo,
      rutas,
      isPickingVehiculo,
      isPickingPedidosToRutas,
      addPedido,
      rutasSelected,
      updateRutasSelected,
      updateMapView,
      updateMostrarOrden,
      itemsMostrarOrden,
      updateRutaFilter,
      rutaFilter,
      updateZonaFilter,
      zonaFilter,
      searchQuery,
      updateSearchQuery,
      addRuta,
      // Cálculos
      preCalculaRutas,
      calculaRutas,
      pickingToRutas,
      pickingToRutasOff,
      exportToExcel,
      exportRutasToExcel,
      fechaDesde,
      fechaHasta,
      test,
      rutaSelect,
      isEditRuta,
      editRuta,
      pedidosPicking,
    }
  },
  created() {
    console.log('Cargar pedidos picking...')
    this.fetchPedidos()
    // this.fetchClientes()
    // this.fetchVehiculos()
    // this.fetchRutas()
    // this.fetchProductos()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
