<template>
  <b-row class="blog-list-wrapper">
    <b-col
      v-for="pedido in pedidosPicking"
      :key="pedido.id"
      md="4"
      sm="6"
      xs="12"
    >
      <b-card
        style="cursor:pointer"
        border-variant="primary"
        bg-variant="transparent"
        @click="$router.push({ name: 'bimbo-pedidos-picking-v2-item', params: { id: pedido.id }})"
      >
        <b-card-body>
          <b-card-title>
            {{ splitedPedido(pedido.id) }}
          </b-card-title>
          <b-media no-body>
            <b-media-body>
              <small>
                {{ pedido.cliente_nombre }}
              </small>
            </b-media-body>
          </b-media>
          <b-media no-body>
            <b-media-body>
              <small class="text-muted">{{ fechaIso(pedido.fecha) }}</small>
            </b-media-body>
          </b-media>
        </b-card-body>
      </b-card>
    </b-col></b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BMedia,
  BMediaBody,
  BCardBody,
} from 'bootstrap-vue'

import usePicking from './usePicking'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaBody,
    // BLink,
    // BBadge,
    // BFormInput,
    // BAvatar,
    // BCardText,
    // BMediaAside,
    // BFormGroup,
    // BInputGroup,
    // BInputGroupAppend,
    // BImg,
    // BPagination,
  },
  setup() {
    const {
      pedidosPicking,
      splitedPedido,
      fechaIso,
    } = usePicking()

    return {
      pedidosPicking,
      splitedPedido,
      fechaIso,
    }
  },
  data() {
    return {
      search_query: '',
      currentPage: 1,
      perPage: 1,
      rows: 140,
    }
  },
}
</script>
